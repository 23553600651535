@import "../_mixins";

.suggestion-wrap{
    display: flex;
    flex-direction: column;
    padding: 0.50em 0.75em;
    width: 100%;
}

.suggestion-top {
    display: flex;
    align-items: center;
    width: 100%;
}

.suggestion-icon{
    height: 16px;
    width: 16px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.suggestion-title-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
}

.suggestion-bottom {
    display: flex;
    width: 100%;
    padding-left: 24px;
}

.suggestion-title1{
    font-size: 15px;
    color: grey;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.suggestion-title2{
    font-size: 12px;
    color: #afafaf;
}

.suggestion-title3{
    font-size: 14px;
    color: #afafaf;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.click-search {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    flex-wrap: wrap;
}

.click-search-query {
    margin-left: 0.3em;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.click-search svg {
    margin: 0 0.4em;
}

.rw-list-optgroup {
    font-weight: 400 !important;
    padding: 1em 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #afafaf;
    position: sticky;
    top: 0;
    background: white;
}

.rw-list {
    overflow-x: hidden !important;
    padding-top: 0 !important;
}

.rw-list-option {
    padding: 0 !important;
    .suggestion-wrap {
      .suggestion-top {
        .suggestion-title-container {
          .suggestion-title1 {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
            line-height: 1;
          }
          .suggestion-title2 {
            > div {
              margin: 0px;
              border-radius: 5px;
              span {
                line-height: 1.2;
              }
            }
          }
        }
      }
    }
}

@media (orientation: portrait) {
  .rw-popup-container {
      margin: 5px -160px 0px -60px;
      .rw-popup {
        .rw-list {
          .rw-list-optgroup {
            padding: 5px 10px;
            .click-search {
              font-size: 12px;
            }
          }
          .rw-list-option {
            .suggestion-wrap {
              padding: 5px 4px;
              .suggestion-top {
                .suggestion-title-container {
                  .suggestion-title1 {
                    span, mark {
                      font-size: 14px !important;
                    }
                  }
                  .suggestion-title2 {
                    > div {
                      padding: 0px !important;
                      span {
                        font-size: 8px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      
  }
}
@media (orientation: landscape) {
    .rw-popup-container {
      margin: 5px -150px 0px -52px;
      .rw-popup {
        .rw-list {
          .rw-list-optgroup {
            padding: 8px 14px;
            .click-search {
              font-size: 12px;
            }
          }
          .rw-list-option {
            .suggestion-wrap {
              .suggestion-top {
                .suggestion-title-container {
                  .suggestion-title2 {
                    > div {
                      span {
                        font-size: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      
    }
}