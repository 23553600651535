@import "../_mixins";
.emblem-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1100;
  position: fixed;
  top: 0px;
  width: 100%;
  background: #ffffff;
  padding: 15px 15px;

  .emblem-left-content {
    display: flex;
    align-items: center;

    .emblem-close-btn {
      color: #000000;
      background: transparent;
      border: none;
      padding: 0;
    }

    .emblem-logo {
      width: 70px;
      padding: 0 3px;
    }

    .emblem-left-textbox {
      display: flex;
      flex-direction: column;

      .emblem-left-text-top {
        font-weight: 600;
      }
      .emblem-left-text-btm {
        color: #939598;
      }
    }
  }

  .emblem-right-content {
    min-width: 96px;
    .emblem-download-btn {
      background: $nextsix-orange;
      color: #ffffff;
      padding: 8px 15px;
      border-radius: 7px;
      text-decoration: none;
    }
  }
}
/* SEARCH BLOCK STYLING START */
.ai-search-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .ai-search-subtitle {
    font-size: 18px;
    margin: 0;
  }
  .ai-search-content {
    display: grid;
    justify-items: center;
    .ai-search-property {
      display: grid;
      width: 90%;
    }
  }
}
.property-ai-search-action {
  background-color: #0E0E0E !important;
  border-color: transparent !important;
  color: white;
  font-size: 10px;
  border-radius: 15px;
  padding: 0.3rem;
  margin-right: 0.7rem;
  height: 100%;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:focus,
  :focus-visible {
    outline: none;
  }
  > img {
    width: auto;
    height: 100%;
  }
  &:disabled {
    background-color: #757373 !important;
    cursor: progress;
  }
}
.ai-search-footer {
  display: flex;
  flex: 1;
  border: 1px solid #FB9600;
  border-radius: 3rem;
  padding: 0.2rem 0.2rem 0.2rem .5rem;
  align-items: center;
  .ai-search-input {
    display: flex;
    flex: 1;
    border: none;
    outline: none;
    margin: 0;
    &:focus-visible {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
}
.property-not-found {
  place-content: center;
  text-align: center;
  height: 80%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  img {
    width: 90%;
    max-width: 270px;
    height: 90%;
    max-height: 270px;
  }
  .property-not-found-title {
    color: red;
  }
  .property-not-found-text {

  }
}
.welcome-component {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
  
  .welcome-component-body {
    display: flex;
    flex-direction: column;
    gap: .3rem;
  }

  .welcome-component-guide-link {
    color: #F09108;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.ai-guide {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
  
  .ai-guide-step-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: .5rem;

    .ai-guide-step-button {
      justify-content: center;
      background-color: #F09108 !important;
      border-color: transparent !important;
      color: white;
      font-size: .7rem;
      border-radius: 15px;
      padding: 0.3rem;
      min-width: 4.5rem;
      height: 100%;
      width: fit-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      &:focus,
      :focus-visible {
        outline: none;
      }
      &:disabled {
        background-color: #a5a5a5 !important;
        cursor: progress;
      }

      .ai-guide-step-button-left-arrow {
        margin-right: 5px;
        width: 18px;
        height: 18px;
      }

      .ai-guide-step-button-right-arrow {
        margin-left: 5px;
        width: 18px;
        height: 18px;
      }
    }
  }

  .ai-guide-close-button {
    color: #F09108;
    
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.property-search-wrapper2 {
  display: flex;
  // background-image:  linear-gradient(0deg, rgba(0, 0, 0, 0.5),  rgba(0, 0, 0, 0.5)), url(../../assets/home/web_cover_photo.jpg);
  background-image:  linear-gradient(0deg, rgba(0, 0, 0, 0),  rgba(0, 0, 0, 0)), url(../../assets/home/web_cover_photo4.jpg);
  max-width: 1222px !important;
  background-repeat: no-repeat;
  background-position: center;
  margin: 95px auto 0;
  justify-content: center;
  background-size: cover;
  padding: 0px !important;
  .property-search-content2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    .property-search-titleGroup2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: #fff;
      font-family: 'Gobold';
      text-transform: uppercase;
      .property-search-firstTitle2 {
        font-weight: 100;
      }
      .property-search-firstTitle3 {
        font-weight: 100;
        color: #fbc51c;
      }
    }
    .property-search-elements2 {
      display: flex;
      flex-direction: column;
      width: calc(100% - 10px);
      background: rgba(0, 0, 0, 0.4);
      border-radius: 4px;
      .property-search-mainCategory2 {
        display: flex;
        justify-content: center;
        text-align: center;
        > button {
          position: relative;
          background-color: transparent;
          border-color: transparent;
          box-shadow: none !important;
          &.active , &:hover {
            &::after {
              content: '';
              display: block;
              height: 3px;
              margin-top: -3px;
              background-color: $nextsix-orange;
            }
          }
        }
      }
      .property-search-barGroup2 {
        width: 100%;
        margin: auto;
        .property-search-bar2 {
          display: flex;
          width: 100%;
          margin: auto;
          background: #fff;
          border-radius: 7px;
          align-items: center;
          justify-content: space-between;
          .property-search-targetFilter2 {
            height: 100%;
            border-right: 1px solid #58595b;
            .property-search-targetToggle2 {
              color: #f09108 !important;
              text-transform: capitalize;
              &:after {
                content: url(../../assets/img/neworangedropdownarrow.svg) !important;
                border: none !important;
                vertical-align: 1.5px !important;
              }
              .property-search-targetLabel2 {
                margin: 0 !important;
              }
            }
            .search-dropdown-menu-scroll {
              overflow: hidden;
            }
          }
          .quicksearch {
            display: flex;
            flex: 1;
            height: 100%;
            .rw-widget-input {
              display: flex;
              box-shadow: none;
              width: 100%;
              &::placeholder {
                color: #afafbe;
              }
              input {
                width: 100%;
              }
            }
            .rw-widget-picker {
              border: none;
              box-shadow: none;
            }
          }
          .property-search-action2 {
            background-color: transparent !important;
            border-color: transparent !important;
            padding: 0px;
            height: 100%;
            &:focus,
            :focus-visible {
              outline: none;
            }
            > img {
              width: auto;
              height: 100%;
            }
          }
        }
      }
      .property-search-paramsBlock2 {
        display: flex;
        justify-content: center;
        width: 100%;
        .btn-group {
          display: flex;
          align-items: center;
          width: auto;
          .property-search-paramsToggle2 {
            display: flex;
            justify-content: center;
            overflow: hidden;
            &:has(.priceRange-dropdownMenu){
              min-width: fit-content;
            }
            &:after {
              content: url(../../assets/img/newwhitedropdownarrow.svg) !important;
              border: none !important;
            }
            .property-search-paramsLabel2 {
              color: #fff;
              text-overflow: ellipsis;
              overflow: hidden;
              &:hover {
                color: #f09108;
              }
            }
            .prop-search-paramsLabel3 {
              color: #333333;
              margin: 0;
            }
          }
          .priceRange-dropdownMenu.search-dropdown-menu {
            .priceRange-dropdownMenu-label {
              display: flex !important;
              align-items: center;
            }
          }
        }
      }
      #home2__paramSearch__propertyType {
        /* cursor: pointer; */
        .searchWrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          border: 0.5px dashed $border-grey;
          border-radius: 4px;
          min-height: 22px;
          margin: 0px;
          padding: 0px;
          text-align: center;
          &:after {
            content: url(../../assets/img/newwhitedropdownarrow.svg) !important;
            border: none !important;
            vertical-align: 2px;
          }
          #home2__paramSearch__propertyType_input {
            cursor: pointer;
            color: #fff;
            font-size: 12px;
            &::placeholder {
              color: #fff;
            }
            &:hover::placeholder {
              color: #f09108;
            }
          }
          > .icon_cancel {
            display: none;
          }
          .chip {
            background: transparent;
            font-size: 12px;
            padding: 0;
            margin: 0;
            cursor: pointer;
            &:hover {
              color: #f09108;
            }
          }
        }
      }
    }
  }
}
/* SEARCH BLOCK STYLING END */
/*  WHATS NEW STYLING START  */
.whatsnew-section2 {
  @include custom-container;
  .whatsnew-section-header2 {
    color: #333333;
    font-weight: 500;
    text-align: center;
  }
  .whatsnew-section-slider {
    .slick-list {
      .slick-slide > div {
        .whatsnew-section-adImg2 {
          width: 100%;
          border-radius: 15px;
        }
      }
    }
    .whatsnew-section-slider-dot {
      li {
        button:before {
          opacity: 1 !important;
          border-radius: 50%;
          background: #bcbec0;
          width: 10px !important;
          height: 10px !important;
          content: "" !important;
        }
        &.slick-active button:before {
          opacity: 1 !important;
          border-radius: 50%;
          background: #f09108;
        }
      }
    }
    .slick-arrow {
      display: none !important;
    }
  }  
}
/*  WHATS NEW STYLING END   */
/*   APP BANNER STYLING START   */
.app-banner-section {
  max-width: 1250px !important;
  .home-search-sub2 {
    // @include custom-container;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: relative;
    width: 100%;
    .sub-mobile-app2 {
      position: absolute;
      > div {
        .gps-text2 {
          font-weight: 500;
          color: #fff;
        }
        .deserve-text2 {
          font-weight: 300;
          color: #fff;
        }
      }
      .sub-mobile-app-button2 {
        display: flex;
        .sub-mobile-app-play2 {
          flex: 1;
        }
        .sub-mobile-app-ios2 {
          flex: 1;
        }
        .sub-mobile-app-img2 {
          width: 100%;
        }
      }
    }
  }
}
.luckydraw {
  @include custom-container;
}
/*  APP BANNER STYLING END   */

.agent-search-input {
  margin: 0 0 0 15px;
  border: none;
  flex: 1;
  &::placeholder {
    color: #afafbe;
  }
  &:focus-visible {
    outline: none;
    background-color: transparent !important;
  }
}

/* PROPERTY DROPDOWN STYLING START */
.search-dropdown-menu {
  max-height: 350px;
}

.search-dropdown-menu-scroll {
  max-height: 350px;
  overflow-y: scroll;
}

/*   PROPERTY DROPDOWN STYLING END   */

/* PROPERTY ROW STYLING START */
.property-row-redirectLink2:hover {
  text-decoration: none;
}
.property-row-seeMoreButton2 {
  color: $nextsix-orange;
  background: none;
  border: none;
}
.property-row-wrapper2 {
  @include custom-container;
  .property-row-content2 {
    background-color: #fff;
    border-radius: 15px;
    .property-row-headerRow2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0px 20px -50px 27px;
      align-items: baseline;
      .property-row-header2 {
        color: #333333;
        font-weight: 500;
        font-size: 34px;
      }
    }
    .property-row-carousel2 {
      @include custom-carousel-wrapper;
      .rec-carousel {
        .rec-arrow-left {
          position: absolute;
          z-index: 999;
          box-shadow: none !important;
          background-color: transparent !important;
          color: #3f3b44 !important;
          border: transparent !important;
          outline: none !important;
          &:hover,
          :focus {
            color: $nextsix-orange !important;
          }
        }
        .rec-slider-container {
          .rec-slider {
            .rec-swipable{
              .rec-carousel-item {
                display: flex;
                .rec-item-wrapper{
                }
              }
            }
          }
        }
        .rec-arrow-right {
          position: absolute;
          z-index: 999;
          box-shadow: none !important;
          background-color: transparent !important;
          color: #3f3b44 !important;
          border: transparent !important;
          outline: none !important;
          &:hover,
          :focus {
            color: $nextsix-orange !important;
          }
        }
      }
    }
  }
}

.home-revamp-ketupat{
  height: 75px;
  width: 55px;
}
/* PROPERTY ROW STYLING END */

//BLOG SECTION STYLING START
.blog_container {
  position: relative;
  .blog_container_inner {
    display: flex;
    flex-direction: column;
    .slick-slider {
      .slick-list {
        .slick-track {
          .slick-slide {
            .blog_entry {
              display: flex;
              align-items: flex-start;
              cursor: pointer;
              & .blog_img {
                display: flex;
                overflow: hidden;
                border-radius: 25px;
                & > img {
                  object-fit: cover;
                }
              }
              & .blog_detail {
                display: flex;
                flex-direction: column;
                & .blog_category {
                  color: #FB9600;
                }
                & .blog_title {
                  color: #333;
                }
                & .blog_description {
                  color: #A7A9AC;
                  text-align: justify;
                }
                & .blog_date {
                  color: #A7A9AC;
                }
              }
            }
          }
        }
      }
    }
  }
  .hide-slick-next{
    .slick-arrow{
      display: none !important;
    }
  }
  .slick-next, .slick-prev {
    top: 50% !important;
    width: 30px !important;
  }
  .slick-next {
    right: 12px !important;
    &::before{
      content: url('../../../src/assets/home/blog-next-icon.svg') !important;
      width: 28px;
    }
  }
  .slick-prev {
    left: 12px !important;
    &::before{
      content: url('../../../src/assets/home/blog-prev-icon.svg') !important;
      width: 28px;
    }
  }

  .slick-custom-next.slick-next{
    right: 85px !important;
    &::before{
      content: url('../../../src/assets/home/blog-view-more-icon.svg') !important;
    }
    // top: 42%;
    // right: -7px;
    // content: url('../../../src/assets/img/button-next.png');
    // cursor: pointer;
  }
  & > div {
    border-radius: 15px;
    background-color: white;
  }
}
//BLOG SECTION STYLING END

/* EXPLORING SECTION STYLING START*/
.exploring-section2 {
  @include custom-container;
  display: flex;
  justify-content: center;
  .exploring-section-content2 {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 15px;
    width: 50%;
    .exploring-section-header2 {
      display: flex;
      flex-direction: column;
      text-align: center;
      .exploring-seciton-title2 {
        font-weight: 500;
        color: #333;
      }
      .exploring-seciton-subtitle2 {
        color: #939598;
      }
    }
    .exploring-section-grid2 {
      display: flex;
      flex-wrap: wrap;
      .exploring-section-imgBox2 {
        position: relative;
        .exploring-section-img2 {
          border-radius: 20px;
          width: 100%;
          height: 220px;
          // max-height: 220px;
          filter: brightness(0.5);
        }
      }
    }
    .exploring-section-textGroup2 {
      display: flex;
      position: absolute;
      flex-direction: column;
      justify-content: space-between;
      width: auto;
      .exploring-section-textHeader2 {
        display: flex;
        flex-direction: column;
        color: white;
        .exploring-section-textLineOne2{
          font-weight: 300;
        }
        .exploring-section-textLineTwo2 {
          font-weight: 500;
          word-break: break-word;
        }
      }
      .exploring-section-viewButton2 {
        display: flex;
        align-items: center;
        width: fit-content;
        background-color: hsla(0, 0%, 100%, .8);
        border-radius: 4px;
        border: none;
        .exploring-section-viewButtonText2 {
          color: #58595b;
        }
      }
    }
  }
}
/* EXPLORING SECTION STYLING END*/


/* BOTTOM DESCRIPTION SECTION START*/
.description-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 68px auto 95px;

  .title {
    font-size: 26px;
    font-weight: 500;
    color: #333333 !important;
    margin: 0 0 10px;
    line-height: normal;
    text-align: center !important;
  }

  .subtitle {
    font-size: 18px;
    color: #939598 !important;
    line-height: normal;
    text-align: center !important;

    a {
      color: #FB9600 !important;
    }
  }
}
/* BOTTOM DESCRIPTION SECTION END*/

/*  MOBILE START*/
@media (min-width: 1px) and (max-width: $screen-mobile-max) {
  .property-search-wrapper2 {
    .property-search-content2 {
      .property-search-titleGroup2 {
        line-height: 1.4;
        gap: 10px;
        padding: 50px 0px 0px 0px;
        .property-search-firstTitle2 {
          font-size: 24px;
          max-width: 200px;
        }
        .property-search-secondTitle2 {
          font-size: 14px;
          max-width: 350px;
        }
        .property-search-secondTitle3 {
          text-transform: uppercase;
          color: #fbc51c;
        }
      }
      .property-search-elements2 {
        margin: 30px auto;
        padding: 25px 10px;
        max-width: 500px;
        .property-search-mainCategory2 {
          gap: 25px;
          margin-bottom: 20px;
          > button {
            padding: 0px 5px;
            font-size: 18px;
          }
        }
        .property-search-barGroup2 {
          .property-search-bar2 {
            max-height: 50px;
            .property-search-targetFilter2 {
              .property-search-targetToggle2 {
                padding: 0px 5px;
                .property-search-targetLabel2 {
                  font-size: 14px;
                }
              }
            }
            .quicksearch {
              .rw-widget-input {
                font-size: 14px;
              }
            }
          }
        }
        .property-search-paramsBlock2 {
          padding: 0px;
          > .btn-group {
            padding: 5px 5px;
            &.property-search-paramsFilter2 {
              max-width: 44%;
            }
            &.search-filter {
              max-width: 26%;
            }
            .property-search-paramsToggle2 {
              padding: 0px;
              &:after {
                margin-top: -5px;
              }
              .property-search-paramsLabel2 {
                font-size: 12px;
                margin: 0px;
              }
              .prop-search-paramsLabel3 {
                font-size: 12px;
              }
            }
            .btn-group {
              .prop-search-paramsToggle3 {
                padding: 0px 10px;
                label {
                  margin: 0px;
                }
              }
            }
          }
          .search-dropdown-menu-scroll.search-dropdown-menu{
            transform: translate3d(5px, 25px, 0px);
          }
          .priceRange-dropdownMenu.search-dropdown-menu {
            transform: translate3d(-210px, 25px, 0px) !important;
          }
        }
        .multiselect-container {
          .search-wrapper {
            .chip {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
  .whatsnew-section2 {
    margin: 10px auto 10px;
    .whatsnew-section-header2 {
      font-size: 24px;
      margin: 0 0 10px 0;
    }
    .whatsnew-section-slider {
      .slick-list {
        margin: 0 -5px;
        .slick-slide > div {
          margin: 0 5px;
        }
      }
      .whatsnew-section-slider-dot {
        li {
          margin: 10px 0 0 0 !important;
          button:before {
            width: 8px !important;
            height: 8px !important;
          }
        }
      }
    }  
  }
  .app-banner-section {
    .home-search-sub2 {
      background-image: url(../../assets/img/whats-your-gps-mobile.png);
      border-radius: 15px;
      aspect-ratio: 2.2;
      margin: 30px 0px;
      .sub-mobile-app2 {
        width: 100%;
        padding: 10px;
        > div {
          .gps-text2 {
            font-size: 22px;
            text-align: center;
            margin: 0px
          }
          .deserve-text2 {
            font-size: 16px;
            text-align: center;
            margin: 0;
          }
        }
        .sub-mobile-app-button2 {
          margin-top: 10px;
          gap: 10px;
        }
      }
    }
  }
  .property-row-seeMoreButton2 {
    font-size: 14px;
  }
  .property-row-wrapper2 {
    margin: 30px auto;
    .property-row-content2 {
      padding: 20px 0;
      .property-row-headerRow2 {
        margin: 0px 20px 0px 27px;
        .property-row-header2 {
          font-size: 26px;
        }
      }
      .property-row-carousel2 {
        margin: 0px 0 0 0;
        .rec-carousel {
          .rec-arrow-left {
            left: -8px;
          }
          .rec-arrow-right {
            right: -8px;
          }
        }
      }
    }
  }
  .blog_container {
    .blog_container_inner {
      padding: 30px 20px;
      .slick-slider {
        margin: 0px 20px;
        .slick-list {
          .slick-track {
            .slick-slide {
              .blog_entry {
                flex-direction: column;
                .blog_img {
                  > img {
                    width: 100%;
                    height: 100%;
                  }
                }
                .blog_detail {
                  gap: 10px;
                  padding: 10px 10px ;
                  & .blog_category {
                    font-size: 16px;
                    line-height: 1.1;
                  }
                  & .blog_title {
                    font-size: 24px;
                    line-height: 1.2;
                  }
                  & .blog_description {
                    line-height: 1.2;
                  }
                }
              }
            }
          }
        }
      }
    }
    .slick-next, .slick-prev {
      top: 50% !important;
      width: 30px !important;
    }
    .slick-next {
      right: 12px !important;
      &::before{
        width: 28px;
      }
    }
    .slick-prev {
      left: 12px !important;
      &::before{
        width: 28px;
      }
    }
  

  }
  .exploring-section2 {
    flex-direction: column;
    margin: 30px auto 0;
    gap: 26px;
    .exploring-section-content2 {
      padding: 20px 10px 25px;
      width: 100%;
      .exploring-section-header2 {
        .exploring-seciton-title2 {
          font-size: 22px;
          line-height: 22px;
          margin: 0 0 5px;
        }
        .exploring-seciton-subtitle2 {
          font-size: 16px;
        }
      }
      .exploring-section-grid2 {
        gap: 10px;
        .exploring-section-imgBox2 {
          width: calc(50% - 5px);
          .exploring-section-textGroup2 {
            height: calc(100% - 20px);
            top: 10px;
            left: 10px;
            .exploring-section-textHeader2 {
              .exploring-section-textLineOne2{
                font-size: 14px;
                margin: 0;
              }
              .exploring-section-textLineTwo2 {
                font-size: 20px;
                margin: 0;
                line-height: 1.3;
                @media (max-width: 400px) {
                  font-size: 16px;
                }
              }
            }
            .exploring-section-viewButton2 {
              gap: 2px;
              width: fit-content;
              padding: 5px 8px;
              .exploring-section-viewButtonText2 {
                font-size: 14px;
                @media (max-width: 400px) {
                  font-size: 10px;
                }
              }
              .exploring-section-exploreArrow2 {
                height: 12px;
              }
            }
          }
        }
      }
    }
  }
  .ai-search-content {
    grid-template-columns: repeat(1, 1fr);
  }
}
/*  MOBILE END  */
/*  TABLET START  */
@media (min-width: $screen-small-tablet-min) and (max-width: $screen-tablet-max) {
  /* SEARCH BLOCK STYLING START */
  .property-search-wrapper2 {
    border-radius: 15px;
    width: 95% !important;
    .property-search-content2 {
      .property-search-titleGroup2 {
        line-height: 1.4;
        gap: 10px;
        padding: 50px 0px 0px 0px;
        .property-search-firstTitle2 {
          font-size: 28px;
          max-width: 300px;
        }
        .property-search-secondTitle2 {
          font-size: 14px;
          max-width: 400px;
        }
        .property-search-secondTitle3 {
          text-transform: uppercase;
          color: #fbc51c;
        }
      }
      .property-search-elements2 {
        margin: 20px auto;
        padding: 20px 50px;
        max-width: 550px;
        .property-search-mainCategory2 {
          gap: 10px;
          margin-bottom: 20px;
          > button {
            padding: 5px 10px;
            font-size: 20px;
          }
        }
        .property-search-barGroup2 {
          .property-search-bar2 {
            max-height: 50px;
            .property-search-targetFilter2 {
              .property-search-targetToggle2 {
                padding: 0px 10px;
                .property-search-targetLabel2 {
                  font-size: 16px;
                }
              }
            }
            .quicksearch {
              .rw-widget-input {
                font-size: 16px;
              }
            }
          }
        }
        .property-search-paramsBlock2 {
          padding: 0px;
          > .btn-group {
            padding: 10px 5px;
            &.property-search-paramsFilter2 {
              max-width: 44%;
            }
            &.search-filter {
              max-width: 26%;
            }
            .property-search-paramsToggle2 {
              padding: 0px;
              &:after {
                margin-top: -5px;
              }
              .property-search-paramsLabel2 {
                font-size: 12px;
                margin: 0px;
              }
              .prop-search-paramsLabel3 {
                font-size: 12px;
              }
            }
            .btn-group {
              .prop-search-paramsToggle3 {
                padding: 0px 10px;
                label {
                  margin: 0px;
                }
              }
            }
          }
          .search-dropdown-menu-scroll.search-dropdown-menu{
            transform: translate3d(5px, 25px, 0px);
          }
          .priceRange-dropdownMenu.search-dropdown-menu {
            transform: translate3d(-210px, 25px, 0px) !important;
          }
        }
        .multiselect-container {
          .search-wrapper {
            .chip {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
  /* SEARCH BLOCK STYLING END */
  /* WHATS NEW STYLING START */
  .whatsnew-section2 {
    margin: 20px auto 20px;
    .whatsnew-section-header2 {
      font-size: 22px;
      margin: 0 0 10px 0;
    }
    .whatsnew-section-slider {
      .slick-list {
        margin: 0 -5px;
        .slick-slide > div {
          margin: 0 5px;
        }
      }
      .whatsnew-section-slider-dot {
        li {
          margin: 10px 0 0 0 !important;
          button:before {
            width: 10px !important;
            height: 10px !important;
          }
        }
      }
    }  
  }
  /* WHATS NEW STYLING END */
  /*   APP BANNER STYLING START   */
  .app-banner-section {
    .home-search-sub2 {
      background-image: url(../../assets/img/whats-your-gps-ipad.png);
      margin: 18px 0 27px 0;
      aspect-ratio: 2.1;
      .sub-mobile-app2 {
        top: 60%;
        left: 40%;
        width: 55%;
        transform: translate(0px, -50%);
        padding: 10px;
        > div {
          .gps-text2 {
            font-size: 22px;
            margin: 0px
          }
          .deserve-text2 {
            font-size: 15px;
            margin: 0;
          }
        }
        .sub-mobile-app-button2 {
          margin-top: 10px;
          gap: 10px;
        }
      }
    }
  }
  /*   APP BANNER STYLING END   */
  /* PROPERTY ROW STYLING START */
  .property-row-seeMoreButton2 {
    font-size: 12px;
  }
  .property-row-wrapper2 {
    margin: 18px auto 18px;
    width: 92%;
    .property-row-content2 {
      padding: 17px 0 0px 0;
      .property-row-headerRow2 {
        margin: 0px 20px 0px 27px;
        .property-row-header2 {
          font-size: 22px;
        }
      }
      .property-row-carousel2 {
        margin: 0px 0 0 0;
        .rec-carousel {
          .rec-arrow-left {
            left: 0px;
          }
          .rec-arrow-right {
            right: 0px;
          }
        }
      }
    }
  }
  /* PROPERTY ROW STYLING END */
  /* EXPLORING SECTION STYLING START*/
  .exploring-section2 {
    flex-direction: column;
    margin: 30px auto 0;
    gap: 26px;
    .exploring-section-content2 {
      padding: 30px 25px 35px;
      width: 100%;
      .exploring-section-header2 {
        .exploring-seciton-title2 {
          font-size: 26px;
          line-height: 26px;
          margin: 0 0 5px;
        }
        .exploring-seciton-subtitle2 {
          font-size: 20px;
        }
      }
      .exploring-section-grid2 {
        gap: 20px;
        .exploring-section-imgBox2 {
          width: calc(50% - 10px);
          .exploring-section-textGroup2 {
            height: calc(100% - 30px);
            top: 15px;
            left: 15px;
            .exploring-section-textHeader2 {
              .exploring-section-textLineOne2{
                font-size: 16px;
                margin: 0;
              }
              .exploring-section-textLineTwo2 {
                font-size: 22px;
                margin: 0;
                line-height: 1.3;
              }
            }
            .exploring-section-viewButton2 {
              gap: 10px;
              width: fit-content;
              padding: 5px 15px;
              .exploring-section-viewButtonText2 {
                font-size: 16px;
              }
              .exploring-section-exploreArrow2 {
                height: 16px;
              }
            }
          }
        }
      }
    }
  }
  /* EXPLORING SECTION STYLING END*/
  /*  BLOG MOBILE STYLING START   */
  .blog_container {
    .blog_container_inner {
      padding: 30px 20px;
      .slick-slider {
        margin: 0px 10px;
        .slick-list {
          .slick-track {
            .slick-slide {
              .blog_entry {
                flex-direction: column;
                .blog_img {
                  > img {
                    width: 100%;
                    height: 100%;
                  }
                }
                .blog_detail {
                  gap: 10px;
                  padding: 20px 15px ;
                  & .blog_category {
                    font-size: 18px;
                    line-height: 1.1;
                  }
                  & .blog_title {
                    font-size: 26px;
                    line-height: 1.2;
                  }
                  & .blog_description {
                    font-size: 18px;
                    line-height: 1.2;
                  }
                }
              }
            }
          }
        }
      }
    }
    .slick-next, .slick-prev {
      top: 50% !important;
      width: 30px !important;
    }
    .slick-next {
      right: 16px !important;
      &::before{
        width: 28px;
      }
    }
    .slick-prev {
      left: 16px !important;
      &::before{
        width: 28px;
      }
    }
  

  }
  /*  BLOG MOBILE STYLING END  */
  .ai-search-content {
    grid-template-columns: repeat(2, 1fr);
  }
}
/*  TABLET END  */
/*  LARGE TABLET START  */
@media (min-width: $screen-laptop-min) and (max-width: $screen-laptop-max) {
  /* SEARCH BLOCK STYLING START */
  .property-search-wrapper2 {
    border-radius: 15px;
    width: 95% !important;
    .property-search-content2 {
      .property-search-titleGroup2 {
        line-height: 1.4;
        gap: 10px;
        padding: 50px 0px 0px 0px;
        .property-search-firstTitle2 {
          font-size: 32px;
          max-width: 500px;
        }
        .property-search-secondTitle2 {
          font-size: 18px;
          max-width: 480px;
        }
        .property-search-secondTitle3 {
          text-transform: uppercase;
          color: #fbc51c;
        }
      }
      .property-search-elements2 {
        margin: 30px auto;
        padding: 30px 20px;
        max-width: 550px;
        .property-search-mainCategory2 {
          gap: 10px;
          margin-bottom: 20px;
          > button {
            padding: 5px 10px;
            font-size: 24px;
          }
        }
        .property-search-barGroup2 {
          .property-search-bar2 {
            max-height: 60px;
            .property-search-targetFilter2 {
              .property-search-targetToggle2 {
                padding: 0px 15px;
                .property-search-targetLabel2 {
                  font-size: 16px;
                }
              }
            }
            .quicksearch {
              .rw-widget-input {
                font-size: 16px;
              }
            }
          }
        }
        .property-search-paramsBlock2 {
          > .btn-group {
            flex: 1;
            padding: 10px 5px;
            .property-search-paramsToggle2 {
              padding: 0px;
              &:after {
                margin-top: -3px;
              }
              .property-search-paramsLabel2 {
                font-size: 14px;
                margin: 0px;
              }
              .prop-search-paramsLabel3 {
                font-size: 14px;
              }
            }
            .btn-group {
              .prop-search-paramsToggle3 {
                padding: 0px 10px;
                label {
                  margin: 0px;
                }
              }
            }
          }
          .search-dropdown-menu-scroll.search-dropdown-menu{
            transform: translate3d(5px, 28px, 0px);
          }
          .priceRange-dropdownMenu.search-dropdown-menu {
            margin-top: 10px;
            transform: translate3d(-180px, 28px, 0px) !important;
          }
        }
        .multiselect-container {
          .search-wrapper {
            .chip {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  /* SEARCH BLOCK STYLING END */

  /* WHATS NEW STYLING START */
  .whatsnew-section2 {
    margin: 60px auto 60px;
    .whatsnew-section-header2 {
      font-size: 26px;
      margin: 0 0 10px 0;
    }
    .whatsnew-section-slider {
      .slick-list {
        margin: 0 -5px;
        .slick-slide > div {
          margin: 0 5px;
        }
      }
      .whatsnew-section-slider-dot {
        li {
          margin: 10px 0 0 0 !important;
          button:before {
            width: 10px !important;
            height: 10px !important;
          }
        }
      }
    }  
  }
  /* WHATS NEW STYLING END */
  .app-banner-section {
    .home-search-sub2 {
      background-image: url(../../assets/img/whats-your-gps.png);
      margin: 0px 0 20px 0;
      aspect-ratio: 3.5;
      background-size: contain;
      .sub-mobile-app2 {
        top: 60%;
        left: 40%;
        width: 45%;
        transform: translate(0px, -50%);
        > div {
          .gps-text2 {
            font-size: 28px;
            margin: 0px
          }
          .deserve-text2 {
            font-size: 20px;
            margin: 0;
          }
        }
        .sub-mobile-app-button2 {
          margin-top: 10px;
          gap: 10px;
        }
      }
    }
  }
  /* PROPERTY ROW STYLING START */
  .property-row-seeMoreButton2 {
    font-size: 16px;
  }
  .property-row-wrapper2 {
    margin: 30px auto;
    .property-row-content2 {
      padding: 15px 0;
      .property-row-headerRow2 {
        margin: 0px 20px 0px 27px;
        .property-row-header2 {
          font-size: 26px;
        }
      }
      .property-row-carousel2 {
        margin: 0px 0 0 0;
        .rec-carousel {
          .rec-arrow-left {
            left: -12px;
          }
          .rec-arrow-right {
            right: -12px;
          }
        }
      }
    }
  }
  /* PROPERTY ROW STYLING END */

  /* PROPERTY DROPDOWN STYLING START */
  #home2__paramSearch__propertyType {
    margin: -12px auto;
  }
  /* PROPERTY DROPDOWN STYLING END */
  .blog_container {
    .blog_container_inner {
      padding: 30px 30px;
      .slick-slider {
        margin: 20px 5px 10px 5px;
        .slick-list {
          .slick-track {
            .slick-slide {
              .blog_entry {
                flex-direction: row;
                padding: 0px 5px;
                .blog_img {
                  width: 40%;
                  > img {
                    width: 100%;
                  }
                }
                .blog_detail {
                  flex: 1;
                  gap: 10px;
                  padding: 0px 30px 0px 15px ;
                  & .blog_category {
                    font-size: 16px;
                    line-height: 1.1;
                  }
                  & .blog_title {
                    font-size: 24px;
                    line-height: 1.2;
                  }
                  & .blog_description {
                    font-size: 16px;
                    padding-right: 30px;
                    line-height: 1.2;
                  }
                }
              }
            }
          }
        }
      }
    }
    .slick-next, .slick-prev {
      top: 50% !important;
      width: 30px !important;
    }
    .slick-next {
      right: 16px !important;
      &::before{
        width: 28px;
      }
    }
    .slick-prev {
      left: 16px !important;
      &::before{
        width: 28px;
      }
    }
  }
  /* EXPLORING SECTION STYLING START*/
  .exploring-section2 {
    margin: 30px auto 0;
    gap: 26px;
    .exploring-section-content2 {
      padding: 30px 15px 35px;
      .exploring-section-header2 {
        .exploring-seciton-title2 {
          font-size: 24px;
          line-height: 25px;
          margin: 0 0 5px;
        }
        .exploring-seciton-subtitle2 {
          font-size: 18px;
        }
      }
      .exploring-section-grid2 {
        gap: 10px;
        .exploring-section-imgBox2 {
          width: calc(50% - 5px);
          .exploring-section-textGroup2 {
            width: calc(100% - 30px);
            height: calc(100% - 20px);
            top: 10px;
            left: 15px;
            .exploring-section-textHeader2 {
              .exploring-section-textLineOne2{
                font-size: 16px;
                margin: 0;
              }
              .exploring-section-textLineTwo2 {
                font-size: 22px;
                margin: 0;
                line-height: 1.3;
              }
            }
            .exploring-section-viewButton2 {
              gap: 8px;
              width: fit-content;
              padding: 5px 15px;
              .exploring-section-viewButtonText2 {
                font-size: 18px;
              }
              .exploring-section-exploreArrow2 {
                height: 18px;
              }
            }
          }
        }
      }
    }
  }
  /* EXPLORING SECTION STYLING END*/
  .ai-search-content {
    grid-template-columns: repeat(3, 1fr);
  }
}
/*  LARGE TABLET END  */
/* DESKTOP START */
@media (min-width: $screen-desktop-min) {
  .property-search-wrapper2 {
    border-radius: 25px;
    background-size: cover;
    .property-search-content2 {
      .property-search-titleGroup2 {
        line-height: 1.4;
        gap: 5px;
        padding: 25px 0px 0px 0px;
        .property-search-firstTitle2 {
          margin-top: 20px;
          font-size: 34px;
          width: 100%;
        }
        .property-search-secondTitle2 {
          font-size: 19.5px;
          width: 100%;
        }
        .property-search-secondTitle3 {
          text-transform: uppercase;
          color: #fbc51c;
        }
      }
      .property-search-elements2 {
        margin: 30px auto 50px auto;
        padding: 25px 28px;
        width: fit-content;
        .property-search-mainCategory2 {
          gap: 10px;
          margin-bottom: 20px;
          > button {
            padding: 5px 10px;
            font-size: 28px;
          }
        }
        .property-search-barGroup2 {
          .property-search-bar2 {
            width: 524px;
            max-height: 60px;
            .property-search-targetFilter2 {
              .property-search-targetToggle2 {
                padding: 5px 10px;
                .property-search-targetLabel2 {
                  font-size: 16px;
                }
              }
            }
            .quicksearch {
              .rw-widget-input {
                font-size: 16px;
              }
            }
            .property-search-action2 {
              margin-right: 10px;
            }
          }
        }
        .property-search-paramsBlock2 {
          padding: 0px;
          > .btn-group {
            padding: 10px 15px;
            .property-search-paramsToggle2 {
              padding: 0px;
              &:after {
                margin-top: -3px;
              }
              .property-search-paramsLabel2 {
                font-size: 14px;

              }
              .prop-search-paramsLabel3 {
                font-size: 14px;
              }
            }
            .btn-group {
              .prop-search-paramsToggle3 {
                padding: 0px 10px;
                label {
                  margin: 0px;
                }
              }
            }
          }
          .search-dropdown-menu-scroll.search-dropdown-menu{
            transform: translate3d(5px, 30px, 0px);
          }
          .priceRange-dropdownMenu.search-dropdown-menu {
            transform: translate3d(-170px, 30px, 0px) !important;
          }
        }
        .multiselect-container {
          .search-wrapper {
            .chip {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
  .whatsnew-section2 {
    margin: 60px auto 60px;
    .whatsnew-section-header2 {
      font-size: 34px;
      margin: 0 0 30px 0;
    }
    .whatsnew-section-slider {
      .slick-list {
        margin: 0 -10px;
        .slick-slide > div {
          margin: 0 10px;
        }
      }
      .whatsnew-section-slider-dot {
        li {
          margin: 15px 0 0 0 !important;
          button:before {
            width: 10px !important;
            height: 10px !important;
          }
        }
      }
    }  
  }
  .app-banner-section {
    .home-search-sub2 {
      background-image: url(../../assets/img/whats-your-gps.png);
      background-size: contain;
      aspect-ratio: 3.5;
      margin: 40px 0px;
      .sub-mobile-app2 {
        top: 50%;
        left: 50%;
        transform: translate(-34%, -27%);
        > div {
          .gps-text2 {
            font-size: 28px;
            margin: 0px
          }
          .deserve-text2 {
            font-size: 22px;
            margin: 0;
          }
        }
        .sub-mobile-app-button2 {
          gap: 10px;
        }
      }
    }
  }
  .property-row-seeMoreButton2 {
    font-size: 18px;
  }
  .property-row-wrapper2 {
    margin: 36px auto;
    .property-row-content2 {
      padding: 25px 0;
      .property-row-headerRow2 {
        margin: 0px 20px -50px 27px;
        .property-row-header2 {
          font-size: 34px;
        }
      }
      .property-row-carousel2 {
        margin: 50px 0 0 0;
        .rec-carousel {
          .rec-arrow-left {
            left: -12px;
          }
          .rec-arrow-right {
            right: -12px;
          }
        }
      }
    }
  }
  .blog_container {
    min-width: 1250px;
    & > div {
      padding: 30px;
      & h3 {
        margin-bottom: 30px;
      }
    }
    .blog_container_inner {
      padding: 30px 30px;
      .slick-slider {
        margin: 0px 10px;
        .slick-list {
          .slick-track {
            .slick-slide {
              .blog_entry {
                flex-direction: row;
                padding: 0px 5px;
                .blog_img {
                  width: 40%;
                  > img {
                    width: 100%;
                  }
                }
                .blog_detail {
                  flex: 1;
                  gap: 10px;
                  padding: 10px 30px 10px 20px ;
                  & .blog_category {
                    font-size: 16px;
                    line-height: 1.1;
                  }
                  & .blog_title {
                    font-size: 24px;
                    line-height: 1.2;
                  }
                  & .blog_description {
                    font-size: 16px;
                    padding-right: 30px;
                    line-height: 1.2;
                  }
                }
              }
            }
          }
        }
      }
    }
    .slick-next, .slick-prev {
      top: 50% !important;
      width: 28px !important;
    }
    .slick-next {
      right: 20px !important;
      &::before{
        width: 28px;
      }
    }
    .slick-prev {
      left: 20px !important;
      &::before{
        width: 28px;
      }
    }
  }
  .exploring-section2 {
    margin: 30px auto 0;
    gap: 26px;
    .exploring-section-content2 {
      padding: 30px 25px 35px;
      .exploring-section-header2 {
        .exploring-seciton-title2 {
          font-size: 26px;
          margin: 0 0 5px;
        }
        .exploring-seciton-subtitle2 {
          font-size: 18px;
        }
      }
      .exploring-section-grid2 {
        gap: 20px;
        .exploring-section-imgBox2 {
          width: calc(50% - 10px);
          .exploring-section-textGroup2 {
            height: calc(100% - 40px);
            top: 20px;
            left: 20px;
            .exploring-section-textHeader2 {
              .exploring-section-textLineOne2{
                font-size: 17px;
                margin: 0;
              }
              .exploring-section-textLineTwo2 {
                font-size: 23px;
                margin: 0;
                line-height: 1.3;
              }
            }
            .exploring-section-viewButton2 {
              gap: 10px;
              width: fit-content;
              padding: 5px 20px;
              .exploring-section-viewButtonText2 {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
  .ai-search-content {
    grid-template-columns: repeat(4, 1fr);
  }
}
/* DESKTOP END */
@media (max-width: 380px) {
  .container {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}